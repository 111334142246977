<template>
  <v-chip
    :color="chipColor"
  >
    <v-icon small class="mr-2">fas fa-envelope</v-icon>
    Confirmation Email {{rows && rows.length ? 'Sent' : 'Unsent'}}
    <v-dialog
      v-model="dialog"
      scrollable
      :persistent="valid || loading"
      max-width="500px"
      transition="dialog-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          color="white"
          v-on="on"
          small
          icon
          :loading="loading"
        >
          <v-icon>fas fa-eye</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar color="color2 color2Text--text" dense>
          Confirmation Email History
          <v-spacer></v-spacer>
          <v-btn
            color="color1 color1Text--text"
            small fab
            @click.stop="dialog = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-0 pb-0">
          <v-window v-model="window" touchless>
            <v-window-item :key="0">
              <v-data-table
                :headers="[
                  {text: 'Sent On', value: 'dtSent'},
                  {text: 'To', value: 'to'}
                ]"
                :items="rows"
                item-key="id"
                :hide-default-footer="!rows || rows.length < 10"
                :loading="loading"
                disable-sort
              >
                <template v-slot:[`item.dtSent`]="{item}">
                  <div>{{item.dtSent | unixToLocalDt}}</div>
                </template>
                <template v-slot:[`item.to`]="{item}">
                  <div v-for="t in item.to" :key="t">{{t}}</div>
                </template>
              </v-data-table>
            </v-window-item>
            <v-window-item :key="1">
              <div class="px-3 pt-2">
                <div class="text-subtitle-2">Select which players to send it to or enter additional email addresses</div>
                <v-checkbox
                  v-for="p in team.players"
                  :key="p.playerProfileId"
                  :label="p.fullname"
                  v-model="playerIds"
                  hide-details
                  color="success"
                  :value="p.playerProfileId"
                ></v-checkbox>
                <v-expand-transition>
                  <v-combobox
                    class="mt-3"
                    v-if="showAdd"
                    v-model="iRecipients"
                    :search-input.sync="search"
                    :items="[]"
                    color="color3"
                    item-color="color3"
                    multiple
                    persistent-hint
                    small-chips
                    deletable-chips
                    label="Additional Recipients"
                    hint="Enter an email addresses to send to"
                    :disabled="loading"
                  >
                    <template slot="no-data" v-if="validEmail">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Press <kbd>enter</kbd> to add this email
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </v-expand-transition>
                <v-expand-transition>
                  <div class="text-end" v-if="!showAdd">
                    <v-btn color="color3" text x-small @click.stop="showAdd = true">add address</v-btn>
                  </div>
                </v-expand-transition>
              </div>
            </v-window-item>
          </v-window>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-container fluid class="py-0">
          <v-row class="pa-0">
            <v-col cols="12" class="pa-0">
              <v-window v-model="window" touchless>
                <v-window-item :key="0">
                  <v-btn
                    color="color3"
                    text
                    @click.stop="window = 1"
                  >resend</v-btn>
                </v-window-item>
                <v-window-item :key="1">
                  <div class="justify-space-between d-flex">
                    <v-btn
                      color="color3Text color3--text"
                      small
                      fab
                      @click.stop="window = 0"
                      :disabled="loading"
                    >
                      <v-icon>fas fa-caret-left</v-icon>
                    </v-btn>
                    <v-btn
                      color="success"
                      :disabled="!valid"
                      :loading="loading"
                      @click.stop="send"
                    >
                      Send{{sendCount > 0 ? ` (${sendCount})` : ''}}
                    </v-btn>
                  </div>
                </v-window-item>
              </v-window>
            </v-col>
          </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-chip>

</template>

<script>
import { emailRegex } from '@/classes/HelperFunctions'
export default {
  props: ['team'],
  data () {
    return {
      showAdd: false,
      dialog: false,
      loading: false,
      window: 0,
      playerIds: [],
      iRecipients: [],
      search: null
    }
  },
  computed: {
    rows () {
      return this.team.registrationData.emailHistory && this.team.registrationData.emailHistory.filter(f => f.to.length > 0)
    },
    chipColor () {
      var c = this.rows && this.rows.length ? 'success' : 'error'
      return `${c} white--text`
    },
    validEmail () {
      return this.search && this.search.match(emailRegex)
    },
    sendCount () {
      return this.playerIds.length + this.iRecipients.length
    },
    valid () {
      return (this.playerIds.length + this.iRecipients.length) > 0
    },
    dto () {
      return {
        playerIds: this.playerIds,
        additions: this.iRecipients
      }
    }
  },
  methods: {
    reset () {
      this.showAdd = false
      this.loading = false
      this.window = 0
      this.playerIds = []
      this.iRecipients = []
      this.search = null
    },
    send () {
      this.loading = true
      this.$VBL.team.confirmationResend(this.team.id, this.dto)
        .then(r => {
          this.team.registrationData = r.data
          this.reset()
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    window: function (v) {
      if (v === 0) this.reset()
    },
    dialog: 'reset'
  }
}
</script>
